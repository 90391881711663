<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-row>
          <b-col>
            <label class="col-form-label-sm field-name-class">
              {{ $t('components.form.credits-pack.credit-pack-informations.credit-label') }}
            </label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <select
                v-model="creditPackItem.paymentToken"
                size="sm"
                class="form-control background-light-blue-inputs"
                @change="requiredCredit = true"
            >
              <option disabled :value="null"> {{$t('general.actions.choose') }}</option>
              <template v-for="(credit, i) of credits">
                <option :value="credit['@id']"> {{ credit.name }}</option>
              </template>
            </select>
            <b-form-invalid-feedback
                :state="requiredCredit"
            >
              {{ $t('validation.required') }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="3">
        <b-row>
          <b-col cols="12">
            <label class="col-form-label-sm field-name-class">
              {{ $t('components.form.credits-pack.credit-pack-informations.credit-quantity') }}
            </label>
          </b-col>
          <b-col cols="12">
              <d-text-field
                  v-model="creditPackItem.quantity"
                  type="number"
                  min="0"
                  size="sm"
                  autocomplete="off"
                  class-name="background-light-blue-inputs"
                  :place-holder="'general.actions.numbers.zero'"
                  @on:text-field:change="requiredQuantity = true"
              />
            <b-form-invalid-feedback
                :state="requiredQuantity"
            >
              {{ $t('validation.required') }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="parseInt(index) === 0" class="description-gray" cols="3">
        {{ $t('components.form.credits-pack.credit-pack-informations.descriptions.credit-pack') }}
      </b-col>
      <b-col class="mt-4" v-else>
        <remove-item-link
            text-link="views.credits-packs.credit-pack-create-update.remove-credit"
            @on:remove-link-click="$emit('on:remove-credit-pack-item', parseInt(index))"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="9">
        <div
            class="border-bottom-grey"
            :class="parseInt(index) > 0 ? 'mt-3' : ''"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { getCredits } from "@api/doinsport/services/credits/credits.api";
import RemoveItemLink from "@custom/subscrtiptions/new/RemoveItemLink";

export default {
  components: { RemoveItemLink },
  data: () => ({
    credits: [],
    requiredQuantity: true,
    requiredCredit: true,
  }),
  props: ['index', 'creditPackItem', 'validator'],
  watch: {
    validator: {
      deep: true,
      handler () {
        if (this.creditPackItem.quantity === '' || this.creditPackItem.quantity === null) {
          this.requiredQuantity = false;
          this.validator.valid = false;
        }
        if (this.creditPackItem.paymentToken === '' || this.creditPackItem.paymentToken === null) {
          this.requiredCredit = false;
          this.validator.valid = false;
        }
      }
    },
  },
  methods: {
    loadCredits() {
      this.credits = [];

      getCredits()
        .then((response) => {
        this.credits = response.data['hydra:member']
      });
    }
  },
  mounted() {
    this.loadCredits();
  }
}
</script>
<style lang="scss" scoped>
.line-height-0 .input-group-text {
  line-height: 0;
}
</style>
